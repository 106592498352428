<template>
  <div class="area_visual">
    <div class="wrap_visual">
      <!-- 분할화면 -->
      <div class="inner_split">
        <!-- 1,2번 박스  : 4-> 6개로 변경될때 top: -100% -->
        <div class="wrap_box type_1">
          <div class="inner_box">
            <div class="box_rolling1 type_1">
              <span class="text_rolling text_intro"><em>Connect</em> Everything</span>
              <NuxtLink
                to="/service/kakaowork"
                class="box_1"
                data-tiara-action-name="메인인트로_카카오워크_클릭"
                data-tiara-action-kind="ClickContent"
                data-tiara-layer="main_intro main_intro_kakaowork_content"
              >
                <div class="inner_1 box_blur">
                  <div class="wrap_icon">
                    <img
                      src="https://t1.kakaocdn.net/dkt_corp/service/mainMotion/motion_logo_1.png"
                      alt=""
                    />
                  </div>
                  <div class="item_rolling">
                    <div class="item_top">
                      <img
                        src="https://t1.kakaocdn.net/dkt_corp/service/mainMotion/kakaowork_1.png"
                        alt=""
                      />
                    </div>
                    <div class="item_bottom">
                      <img
                        src="https://t1.kakaocdn.net/dkt_corp/service/mainMotion/kakaowork_2.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div class="box_info">
                  <div class="wrap_text">
                    <span class="text_info"> 쌍방향 AI Agent 탑재<br />종합 그룹웨어 </span>
                  </div>
                </div>
              </NuxtLink>
            </div>
            <div class="box_rolling1 type_2">
              <span class="text_intro"><em>Connect</em> Everything</span>
            </div>
          </div>
        </div>
        <div class="wrap_box type_2">
          <NuxtLink
            to="/service/kakaoi"
            class="inner_box box_2"
            data-tiara-action-name="메인인트로_카카오i_클릭"
            data-tiara-action-kind="ClickContent"
            data-tiara-layer="main_intro main_intro_kakaoi_content"
          >
            <div class="box_blur">
              <div class="wrap_icon">
                <img
                  src="https://t1.kakaocdn.net/dkt_corp/service/mainMotion/motion_logo_2.png"
                  alt=""
                />
              </div>
              <div class="item_rolling">
                <div class="item_top">
                  <img
                    src="https://t1.kakaocdn.net/dkt_corp/service/mainMotion/kakaoi_1.png"
                    alt=""
                  />
                </div>
                <div class="item_bottom">
                  <img
                    src="https://t1.kakaocdn.net/dkt_corp/service/mainMotion/kakaoi_2.png"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div class="box_info">
              <div class="wrap_text">
                <span class="text_info"> B2B<br />대화형 AI 플랫폼 </span>
              </div>
            </div>
          </NuxtLink>
        </div>
        <div class="wrap_box type_3">
          <NuxtLink
            to="/service/kakaoiconnectcenter"
            class="inner_box box_3"
            data-tiara-action-name="메인인트로_카카오i커넥트센터_클릭"
            data-tiara-action-kind="ClickContent"
            data-tiara-layer="main_intro main_intro_kakaoiconnectcenter_content"
          >
            <div class="box_blur">
              <strong>kakao i Connect</strong>
              <span>Center</span>
            </div>
            호버시 보이는 영역 - item
            <div class="box_info">
              <div class="wrap_text">
                <span class="text_info"> 가장 카카오스러운<br />고객상담 경험 </span>
              </div>
            </div>
          </NuxtLink>
        </div>
        <!-- 4,5번 박스  : 4-> 4개로 변경될때 top: -100% -->
        <div class="wrap_box type_4">
          <div class="inner_box">
            <div class="box_rolling2 type_top"></div>
            <NuxtLink
              to="/service/kakaoiconnecttalk"
              class="box_rolling2 box_4"
              data-tiara-action-name="메인인트로_카카오i커넥트톡_클릭"
              data-tiara-action-kind="ClickContent"
              data-tiara-layer="main_intro main_intro_kakaoiconnecttalk_content"
            >
              <div class="box_blur">
                <strong>kakao i Connect</strong>
                <span>Talk</span>
              </div>

              <div class="box_info">
                <div class="wrap_text">
                  <span class="text_info"> 대화를 기회로 전환하는 <br />AI 챗봇 </span>
                </div>
              </div>
            </NuxtLink>
          </div>
        </div>
        <div class="wrap_box type_5">
          <div class="inner_box">
            <div class="box_rolling2 type_top"></div>
            <NuxtLink
              to="/service/kakaoiconnectmsg"
              class="box_rolling2 box_5"
              data-tiara-action-name="메인인트로_카카오i커넥트메세지_클릭"
              data-tiara-action-kind="ClickContent"
              data-tiara-layer="main_intro main_intro_kakaoiconnectmsg_content"
            >
              <div class="box_blur">
                <strong>kakao i Connect</strong>
                <span>Message</span>
              </div>

              <div class="box_info">
                <div class="wrap_text">
                  <span class="text_info"> 메세지 발송 플랫폼 </span>
                  <span class="sub_info">문자, 카카오톡, RCS, 카드영수증, 전자문서 등</span>
                </div>
              </div>
            </NuxtLink>
          </div>
        </div>
        <div class="wrap_box type_6">
          <NuxtLink
            to="/service/kakaoiconnectalways"
            class="inner_box box_6"
            data-tiara-action-name="메인인트로_카카오i커넥트올웨이즈_클릭"
            data-tiara-action-kind="ClickContent"
            data-tiara-layer="main_intro main_intro_kakaoiconnectalways_content"
          >
            <div class="box_blur">
              <strong>kakao i Connect</strong>
              <span>Always</span>
            </div>

            <div class="box_info">
              <div class="wrap_text">
                <span class="text_info">카카오 채널 기반<br />상담톡 전문 솔루션</span>
              </div>
            </div>
          </NuxtLink>
        </div>
        <div class="wrap_box type_7">
          <NuxtLink
            to="/service/dx"
            class="inner_box box_7"
            data-tiara-action-name="메인인트로_dx_클릭"
            data-tiara-action-kind="ClickContent"
            data-tiara-layer="main_intro main_intro_dx_content"
          >
            <div class="box_blur"></div>

            <div class="box_info">
              <div class="wrap_text">
                <span class="text_info type_small"
                  >컨설팅부터 시스템 구축 / 운영까지<br />고객 맞춤 통합 서비스</span
                >
              </div>
            </div>
          </NuxtLink>
        </div>
        <div class="wrap_box type_8">
          <NuxtLink
            to="/service/sap"
            class="inner_box box_8"
            data-tiara-action-name="메인인트로_sap_클릭"
            data-tiara-action-kind="ClickContent"
            data-tiara-layer="main_intro main_intro_sap_content"
          >
            <div class="box_blur"><strong>SAP ERP</strong></div>

            <div class="box_info">
              <div class="wrap_text">
                <span class="text_info type_small"
                  >기업 비즈니스 경영환경에 최적화된<br />SAP의 컨설팅, 구축, 운영 통합 서비스</span
                >
              </div>
            </div>
          </NuxtLink>
        </div>
        <div class="wrap_box type_9">
          <NuxtLink
            to="/service/ito"
            class="inner_box box_9"
            data-tiara-action-name="메인인트로_ito_클릭"
            data-tiara-action-kind="ClickContent"
            data-tiara-layer="main_intro main_intro_ito_content"
          >
            <div class="box_blur">
              <strong>ITO</strong>
            </div>

            <div class="box_info">
              <div class="wrap_text">
                <span class="text_info type_small"
                  >서비스 영역 별 뛰어난 전문가로 구성된<br />최상의 IT Outsourcing 서비스</span
                >
              </div>
            </div>
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
//9분할 화면 쪼개기
@keyframes splitMotion {
  // 1
  0% {
    width: 300vw;
    height: calc(300vh - 240px);
  }
  3% {
    width: 300vw;
    height: calc(300vh - 240px);
  }
  //2
  15% {
    width: 150vw;
    height: calc(300vh - 240px);
  }
  23% {
    width: 150vw;
    height: calc(300vh - 240px);
  }
  //4
  37% {
    width: 150vw;
    height: calc(150vh - 120px);
  }
  45% {
    width: 150vw;
    height: calc(150vh - 120px);
  }
  60% {
    width: 150vw;
    height: calc(150vh - 120px);
  }
  65% {
    width: 150vw;
    height: calc(150vh - 120px);
  }
  //6
  75% {
    width: 150vw;
    height: calc(100vh - 80px);
  }
  85% {
    width: 150vw;
    height: calc(100vh - 80px);
  }
  //9
  100% {
    width: 100vw;
    height: calc(100vh - 80px);
  }
}
// 4,5번 박스 굴리기
@keyframes RollingSplitBox {
  0% {
    top: 0;
  }
  60% {
    top: 0;
  }
  80% {
    top: -100%;
  }
  100% {
    top: -100%;
  }
}
//1,2번 박스 로고아래 텍스트 변경
@keyframes itemReveal {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes itemDisappear {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
//모션이 끝날때까지 커버 덮어놓는 역할
@keyframes controlCover {
  99% {
    z-index: 0;
  }
  100% {
    z-index: -1;
  }
}
// 인트로 배경 모션
@keyframes backgroundIntro {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  20% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  60% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
}
// 인트로 텍스트 사라지기
@keyframes disappearIntroText {
  0% {
    top: 50%;
  }
  70% {
    top: 50%;
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    top: -10%;
    opacity: 0;
  }
}
// 인트로 -> 기본박스로 변경
@keyframes revealDefaultBox {
  0% {
    top: 100%;
  }
  70% {
    top: 100%;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
// 2,3,6번 박스내부 소리아이콘 나타나기
@keyframes revealSoundIcon {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.area_visual {
  position: fixed;
  top: 80px;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  height: calc(100vh - 80px);
  font-size: 0;
}
.wrap_visual {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100vw;
  height: calc(100vh - 80px);
}
.inner_split {
  width: 300vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    animation-name: controlCover;
    animation-duration: 4.5s;
    animation-fill-mode: forwards;
  }
  animation-name: splitMotion;
  animation-delay: 2s;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  height: calc(300vh - 240px);
}
.wrap_box {
  overflow-x: auto;
  position: relative;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  &:hover {
    .box_info {
      opacity: 1;
    }
    .box_blur {
      filter: blur(4px);
      -webkit-filter: blur(4px);
    }
  }
  .inner_box {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &.type_4,
  &.type_5 {
    overflow: hidden;
    position: relative;
    .inner_box {
      position: absolute;
      display: block;
      width: 100%;
      height: 200%;
      animation-name: RollingSplitBox;
      animation-delay: 2s;
      animation-duration: 2.5s;
      animation-fill-mode: forwards;
    }
  }
  .box_rolling1 {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    .text_intro {
      font-family: 'kakao';
      font-size: 45px;
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      font-weight: 300;
      text-align: center;
      em {
        font-weight: 700;
      }
    }
    .text_rolling {
      animation-name: disappearIntroText;
      animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }
    &.type_1 {
      top: 0;
      left: 0;
      background-color: #1846f2;
      color: #fff;
    }
    &.type_2 {
      top: 0;
      background-color: #fff;
      left: 0;
      z-index: 1;
      animation-name: backgroundIntro;
      animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }
  }
  .box_rolling2 {
    height: 50%;
  }
  .item_rolling {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 50px;
    .item_top,
    .item_bottom {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 100%;
    }
    .item_top {
      animation-name: itemDisappear;
      animation-delay: 2s;
      animation-duration: 2.5s;
      animation-fill-mode: forwards;
    }
    .item_bottom {
      animation-name: itemReveal;
      animation-delay: 2s;
      animation-duration: 2.5s;
      animation-fill-mode: forwards;
      opacity: 0;
    }
  }
}
.box_info {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(38, 38, 38, 0.9);
  text-align: center;
  transition: opacity 0.5s;
}
.box_4,
.box_5 {
  .box_info {
    height: 50%;
    bottom: 0;
    top: auto;
  }
}
.wrap_text {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  color: #fff;
  &:after {
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    margin: 30px auto 0;
    @include backgroundImage(
      "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M32 16c0 8.837-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0s16 7.163 16 16zM18.273 8.39l7.611 7.588-7.608 7.63-1.888-1.883 4.38-4.392H6.666v-2.666h14.125l-4.402-4.39 1.883-1.888z' fill='%23fff'/%3E%3C/svg%3E",
      cover,
      center
    );
  }
}
.text_info {
  display: block;
  font-family: 'NotoSans';
  font-size: 30px;
  font-weight: 700;
  &.type_small {
    font-weight: 400;
    font-size: 24px;
  }
}
.sub_info {
  font-size: 18px;
  margin-top: 10px;
}
//9분할 박스 내부 디자인
.box_blur {
  width: 100%;
  height: 100%;
}
.box_1 {
  position: absolute;
  top: -100%;
  width: 100%;
  height: 100%;
  animation-name: revealDefaultBox;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  .inner_1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  img {
    width: 100%;
  }
  .wrap_icon {
    width: 133px;
    max-width: 100%;
  }
  .item_rolling {
    margin-top: 24px;
  }
  .item_top {
    img {
      max-width: 383px;
    }
  }
  .item_bottom {
    img {
      max-width: 193px;
    }
  }
}
.box_2 {
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f5f5f5;
  .box_blur {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
  }
  img {
    width: 100%;
  }
  .wrap_icon {
    width: 245px;
    max-width: 100%;
  }
  .item_rolling {
    margin-top: 35px;
  }
  .item_top {
    img {
      width: 474px;
    }
  }
  .item_bottom {
    img {
      width: 387px;
    }
  }
}
.box_3 {
  background-color: #ffe32c;
  .box_blur {
    @include backgroundImage(
      'https://t1.kakaocdn.net/dkt_corp/service/mainMotion/motion_ryon.png',
      207px auto,
      center bottom -5px
    );
  }
}
.type_4 {
  background-color: #ffd618;
}
.type_5 {
  background-color: #ffe32c;
}
.type_6 {
  background-color: #ffd618;
}
.type_7 {
  background-color: #262626;
}
.type_8 {
  background-color: #f5f5f5;
}
.type_9 {
  background-color: #e4e4e4;
}
.wrap_box.type_4 {
  .box_rolling2 {
    &.type_top {
      background-color: #262626;
      @include backgroundImage(
        'https://t1.kakaocdn.net/dkt_corp/service/mainMotion/motion_logo_4-1.png',
        426px auto,
        center
      );
    }
  }
}
.wrap_box.type_5 {
  .box_rolling2 {
    &.type_top {
      background-color: #ffd618;
      @include backgroundImage(
        'https://t1.kakaocdn.net/dkt_corp/service/mainMotion/motion_logo_5-1.png',
        426px auto,
        center
      );
    }
  }
}
.box_7 {
  .box_blur {
    @include backgroundImage(
      'https://t1.kakaocdn.net/dkt_corp/service/mainMotion/motion_logo_7.png',
      265px auto,
      center
    );
  }
}
.box_8 {
  .box_blur {
    @include backgroundImage(
      'https://t1.kakaocdn.net/dkt_corp/service/mainMotion/motion_image_8.png',
      100% 50%,
      center bottom
    );
  }
}
.box_9 {
  .box_blur {
    @include backgroundImage(
      'https://t1.kakaocdn.net/dkt_corp/service/mainMotion/motion_logo_9.png',
      253px auto,
      center
    );
  }
}
.box_2,
.box_3,
.box_6 {
  .box_blur {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 30px;
      right: 30px;
      width: 28px;
      height: 20px;
      animation-name: revealSoundIcon;
      animation-delay: 2s;
      animation-duration: 2.5s;
      animation-fill-mode: forwards;
      @include backgroundImage(
        'https://t1.kakaocdn.net/dkt_corp/service/mainMotion/icon_sound.png',
        cover,
        center
      );
    }
  }
}
.box_3,
.box_4,
.box_5,
.box_6,
.box_7,
.box_8,
.box_9 {
  display: block;
}
.box_2,
.box_3,
.box_4,
.box_5,
.box_6,
.box_8,
.box_9 {
  .box_blur {
    box-sizing: border-box;
    padding: 30px;
    font-family: 'kakao';
    font-size: 24px;
  }
  strong {
    display: block;
    line-height: 1;
  }
  span {
    line-height: 1.42;
  }
}

.box_4,
.box_5,
.box_6 {
  .box_blur {
    @include backgroundImage('', 220px 220px, center);
  }
}
.box_4 {
  .box_blur {
    background-image: url('https://t1.kakaocdn.net/dkt_corp/service/mainMotion/motion_icon_4.png');
  }
}
.box_5 {
  .box_blur {
    background-image: url('https://t1.kakaocdn.net/dkt_corp/service/mainMotion/motion_icon_5.png');
    background-size: 220px 175px;
  }
}
.box_6 {
  .box_blur {
    background-image: url('https://t1.kakaocdn.net/dkt_corp/service/mainMotion/motion_icon_6.png');
  }
}
@media screen and (max-width: 1919px) {
  .box_1 {
    .wrap_icon {
      width: 106px;
    }
    .item_rolling {
      margin-top: 20px;
    }
    .item_bottom {
      img {
        max-width: 154px;
      }
    }
  }
  .box_2 {
    .wrap_icon {
      width: 196px;
    }
    .item_rolling {
      margin-top: 30px;
    }
  }
  .box_3 {
    .box_blur {
      background-size: 165px auto;
    }
  }
  .box_4,
  .box_6 {
    .box_blur {
      background-size: 176px 176px;
    }
  }
  .box_5 {
    .box_blur {
      background-size: 190px 150px;
    }
  }
  .box_7 {
    .box_blur {
      background-size: 212px auto;
    }
  }
}
@media screen and (max-width: 1728px) {
  .box_1 {
    .wrap_icon {
      width: 85px;
    }
    .item_rolling {
      margin-top: 15px;
    }
    .item_top {
      img {
        max-width: 224px;
      }
    }
    .item_bottom {
      img {
        max-width: 154px;
      }
    }
  }
  .box_2 {
    .wrap_icon {
      width: 157px;
    }
    .item_rolling {
      margin-top: 20px;
    }
    .item_top {
      img {
        max-width: 340px;
      }
    }
    .item_bottom {
      img {
        max-width: 249px;
      }
    }
  }
  .box_3 {
    .box_blur {
      background-size: 132px auto;
    }
  }
  .box_2,
  .box_3,
  .box_6 {
    .box_blur {
      &:after {
        top: 20px;
        right: 20px;
      }
    }
  }
  .box_2,
  .box_3,
  .box_4,
  .box_5,
  .box_6,
  .box_8,
  .box_9 {
    .box_blur {
      padding: 20px;
      font-size: 20px;
    }
  }
  .box_4,
  .box_6 {
    .box_blur {
      background-size: 140px 140px;
    }
  }
  .box_5 {
    .box_blur {
      background-size: 150px 118px;
    }
  }
  .wrap_box.type_4 {
    .box_rolling2 {
      &.type_top {
        background-size: 44% auto;
      }
    }
  }
  .wrap_box.type_5 {
    .box_rolling2 {
      &.type_top {
        background-size: 38% auto;
      }
    }
  }
  .box_7 {
    .box_blur {
      background-size: 170px auto;
    }
  }
  .box_9 {
    .box_blur {
      background-size: 203px auto;
    }
  }
  .text_info {
    font-size: 24px;
    &.type_small {
      font-size: 20px;
    }
  }
  .sub_info {
    font-size: 16px;
  }
}
@media screen and (max-width: 1023px) {
  .box_2 {
    .wrap_icon {
      width: 157px;
    }
    .item_rolling {
      display: none;
    }
  }
  .wrap_box {
    .box_rolling1 {
      .text_intro {
        font-size: 40px;
      }
    }
  }
}
@media screen and (max-width: 811px) {
  @keyframes splitMotion {
    0% {
      width: 300vw;
      height: calc(300vh - 180px);
    }
    20% {
      width: 150vw;
      height: calc(300vh - 180px);
    }
    40% {
      width: 150vw;
      height: calc(150vh - 90px);
    }
    60% {
      width: 150vw;
      height: calc(150vh - 90px);
    }
    80% {
      width: 150vw;
      height: calc(100vh - 60px);
    }
    100% {
      width: 100vw;
      height: calc(100vh - 60px);
    }
  }
  .area_visual {
    height: calc(100vh - 60px);
  }
  .wrap_visual {
    height: calc(100vh - 60px);
  }
  .inner_split {
    height: calc(300vh - 180px);
  }
  .area_visual {
    top: 60px;
  }
  .wrap_box {
    &:hover {
      .box_info {
        display: none;
      }
      .box_blur {
        filter: blur(0);
        -webkit-filter: blur(0);
      }
    }
    .box_rolling1 {
      .text_intro {
        font-size: 20px;
      }
    }
  }
  .box_1 {
    .wrap_icon {
      width: 62px;
    }
    .item_bottom {
      img {
        max-width: 87px;
      }
    }
  }
  .box_2 {
    .wrap_icon {
      width: 79px;
    }
    .item_rolling {
      margin-top: 15px;
      .item_top {
        img {
          width: 100%;
        }
      }
      .item_bottom {
        align-items: center;
        img {
          width: 100%;
        }
      }
    }
  }

  .box_2,
  .box_3,
  .box_6 {
    .box_blur {
      &:after {
        width: 14px;
        height: 10px;
        background-size: cover;
        right: 12px;
      }
    }
  }
  .box_4,
  .box_6 {
    .box_blur {
      background-size: 60px auto;
    }
  }
  .box_5 {
    .box_blur {
      background-size: 74px auto;
    }
  }
  .box_7 {
    .box_blur {
      background-size: 80px auto;
    }
  }
  .box_9 {
    .box_blur {
      background-size: 80px auto;
    }
  }
  .box_2,
  .box_3,
  .box_4,
  .box_5,
  .box_6,
  .box_8,
  .box_9 {
    .box_blur {
      font-size: 16px;
    }
  }
}
@media screen and (max-width: 470px) {
  .box_3 {
    .box_blur {
      background-size: 80px auto;
    }
  }
}
</style>
