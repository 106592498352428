<template>
  <div
    v-if="isOpen"
    class="notice_layer"
  >
    <h2 class="screen_out">공지사항</h2>
    <div class="layer_body">
      <div
        class="area_content"
        v-html="props.content"
      ></div>
    </div>
    <div class="layer_foot">
      <CheckboxBasic
        :id="'notToday'"
        v-model="notTodayValue"
        :title="'오늘하루 안보기'"
        :style="'notice'"
      />
    </div>
    <button
      type="button"
      class="btn_close"
      @click="closeLayer"
    >
      <span class="screen_out">닫기</span>
    </button>
  </div>
</template>

<script setup>
import { useDimmedOpen } from '/stores/store';
const dimmedOpen = useDimmedOpen();
const isOpen = ref(false);
const notTodayValue = ref('');

const props = defineProps({
  content: {
    type: String,
    default() {
      return '';
    },
  },
});

const getCookie = () => {
  const value = document.cookie.match('(^|;) ?noticePopup=([^;]*)(;|$)');
  const notToday = value ? unescape(value[2]) : null;
  if (notToday !== 'done') {
    isOpen.value = true;
  }
};

const setCookie = () => {
  //쿠키설정
  const todayDate = new Date();
  todayDate.setDate(todayDate.getDate() + 1);
  document.cookie = `noticePopup=done; path=/; expires=${todayDate.toGMTString()};`;
};

const closeLayer = () => {
  if (notTodayValue.value) {
    //오늘 안열기체크되있는경우 캐시처리하자
    setCookie();
  }
  isOpen.value = false; //닫기
  dimmedOpen.toggleDimmed('close');
};
//캐시체크해서 캐시가 있는 경우 열지말자!

onMounted(() => {
  getCookie();
  if (isOpen.value) {
    dimmedOpen.toggleDimmed('open');
  }
});
onUnmounted(() => {
  dimmedOpen.toggleDimmed('close');
});
</script>
<style lang="scss" scoped>
// 스크롤바 커스터마이징
.layer_body::-webkit-scrollbar {
  width: 4px;
}
.layer_body::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}
.layer_body::-webkit-scrollbar-track {
  background: #fff;
}

.notice_layer {
  box-sizing: border-box;
  position: fixed;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  z-index: 100000;
  transform: translate(-50%, -50%);
  width: 880px;
  height: 700px;
  max-height: 80%;
  background-color: #fff;
  border-radius: 16px;
  padding: 50px 40px 40px;
}
.layer_body {
  overflow-y: auto;
  height: 570px;
}
.area_content {
  //에디터 영역
  * {
    word-break: break-all;
  }
}
.layer_foot {
  margin-top: 24px;
}
.btn_close {
  position: absolute;
  top: 24px;
  right: 24px;
  @include setSize(24px, 24px);
  @include closeBtnIcon();
}
@media screen and (max-width: 1023px) {
  .notice_layer {
    width: 100%;
    height: auto;
    max-width: 100%;
    top: auto;
    bottom: 0;
    left: 0;
    transform: translate(0, 0);
    padding: 57px 16px 40px;
    border-radius: 8px 8px 0 0;
  }
  .layer_body {
    height: 100%;
  }
}
</style>
