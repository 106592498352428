<template>
  <h2 class="screen_out">홈</h2>
  <article
    id="mainContent"
    class="content-article"
  >
    <h3 class="screen_out">홈 본문</h3>
    <VisualMain :style="isHide ? 'opacity:0' : ''" />
    <div class="wrap_main">
      <div class="cont_main">
        <h4 class="tit_main">
          Connect Everything<br />
          지속 가능한 성장, <span class="mbr"></span>디케이테크인과 함께하세요
        </h4>
        <div class="area_business">
          <div class="wrap_model">
            <div class="box_model box_work">
              <strong class="tit_model">업무 관리 모델</strong>
              <img
                class="img_model"
                :src="`https://t1.kakaocdn.net/dkt_corp/service/img_modelwork_${mode}.png`"
                alt="kakaowork - 메신저와 솔루션이 교집합하는 그래프"
              />
            </div>
            <span class="ico_change ir_pm">상호호환</span>
            <div class="box_model box_business">
              <strong class="tit_model">비즈니스 맞춤형 정보화 모델</strong>
              <img
                class="img_model"
                :src="`https://t1.kakaocdn.net/dkt_corp/service/img_modelbusiness_${mode}.png`"
                alt="DPG, CRM, CX Innovation, HCM, SCM, PLM, CAX, MSP, ITO, SI, BTL/BTO, Digital Healthcare"
              />
            </div>
            <span class="ico_change ir_pm">상호호환</span>
            <div class="box_model box_ai">
              <strong class="tit_model">AI 커뮤니케이션 모델</strong>
              <img
                class="img_model"
                :src="`https://t1.kakaocdn.net/dkt_corp/service/img_modelai_${mode}.png`"
                alt="kakao i(kakao i, Home, i Auto), kakao i connect(Center, Always, TAIK, Message)"
              />
            </div>
          </div>
          <div class="wrap_tech">
            <div
              v-for="({ id, tit, techs }, index) in techData"
              :key="`${id}_${index}`"
              class="box_tech"
            >
              <em class="tit_tech">{{ tit }}</em>
              <ul class="list_tech">
                <li
                  v-for="tech in techs"
                  :key="tech"
                >
                  {{ tech }}
                </li>
              </ul>
            </div>
          </div>
          <span class="screen_out">비슷한 기술을 제공하는 회사</span>
          <ul class="list_techImgs">
            <li
              v-for="{ id, alt, imgUrl } in techImgData"
              :key="id"
            >
              <img
                :src="imgUrl"
                :class="`img_${id}`"
                :alt="alt"
              />
            </li>
          </ul>
        </div>
      </div>
      <div class="cont_main cont_cate">
        <h4 class="tit_main">고객사례</h4>
        <ListCategory
          title="고객사례"
          :listData="data.listCase"
          :page="'main'"
        />
      </div>
      <div class="cont_main cont_cate">
        <h4 class="tit_main">뉴스</h4>
        <ListCategory
          :title="'뉴스'"
          :listData="data.listNews"
          :page="'main'"
        />
      </div>
      <BannerConsult :logInfo="{ page: 'main', name: '메인' }" />
    </div>
  </article>
  <LayerNotice
    v-if="data.listPopups[0].view"
    :content="data.listPopups[0].contents"
  />
</template>
<script setup>
import { errorPageHandlerCommon } from '/composables/error';
import { fetchMain } from '/api/admin';
import { useIsWidth } from '/stores/store';

const isWidthStore = useIsWidth();
const isHide = ref(false);

const { data, error } = await useAsyncData('mainList', () => fetchMain());

if (error.value && error.value.statusCode) {
  errorPageHandlerCommon(error.value.statusCode);
}

const techData = [
  {
    id: 'kakao',
    tit: 'Tech Alliance with kakao',
    techs: [
      'SAP ERP',
      'kakao i INSIDE',
      'Sync',
      'Big Data',
      'Cloud',
      'Streaming',
      'Healthcare',
      'AIOT',
      'Image Processing',
      'Mobillity',
      'Kanana(AI)',
      'Payment',
      'Vision AI',
      'Search Engine',
      'Local API',
      'kakao AI Assistant (LLM, Advanced ML, STT, TTS, User Custom LM, AM)',
    ],
  },
  {
    id: 'techin',
    tit: 'Tech Platform with dk techin',
    techs: [
      'AI Agent',
      'DT Assessment',
      'Consulting',
      'Tech Methodology',
      'Security',
      'Development Platform (UX Standard, DevOps Standard, Test Automation SW Asset, SER)',
    ],
  },
];

const techImgData = [
  {
    id: 'aws',
    alt: 'aws',
    imgUrl: 'https://t1.kakaocdn.net/dkt_corp/service/img_aws.png',
  },
  {
    id: 'google',
    alt: 'Google Gloud',
    imgUrl: 'https://t1.kakaocdn.net/dkt_corp/service/img_googlecloud.png',
  },
  {
    id: 'kakao',
    alt: 'kakaocloud',
    imgUrl: 'https://t1.kakaocdn.net/dkt_corp/service/img_kakaocloud.png',
  },
  {
    id: 'microsoft',
    alt: 'Microsoft Azure',
    imgUrl: 'https://t1.kakaocdn.net/dkt_corp/service/img_microsoft.png',
  },
  {
    id: 'premise',
    alt: 'On-Premise',
    imgUrl: 'https://t1.kakaocdn.net/dkt_corp/service/img_premise.png',
  },
];

const mode = computed(() => {
  if (isWidthStore.resolutionList.isMo) return 'mo';
  if (isWidthStore.resolutionList.isTablet) return 'tablet';
  return 'pc';
});

const hideVisual = () => {
  isHide.value = scrollY > window.innerHeight + 100;
};

// 티아라로그
onMounted(() => {
  TiaraTrackPage('main', '홈_PV');
  window.addEventListener('scroll', hideVisual);
});
onUnmounted(() => {
  window.removeEventListener('scroll', hideVisual);
});
</script>

<style lang="scss" scoped>
.wrap_main {
  position: relative;
  margin-top: 100vh;
  background: #fff;
}
.cont_main {
  max-width: 1384px;
  padding: 140px 128px;
  margin: 0 auto;
}
.tit_main {
  font-size: 36px;
  font-weight: 700;
  font-family: 'KakaoBig';
  line-height: 1.39;
  color: #1d1d1f;
  letter-spacing: -2px;
}
.area_business {
  max-width: 1279px;
  margin: 74px auto 10px;
}
.tit_business {
  display: block;
  padding: 10px;
  font-size: 22px;
  font-family: 'Kakao';
  font-weight: 700;
  line-height: 1.18;
  text-align: center;
  color: #000;
  background: #f4f4f4;
}
.wrap_model {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
}
.box_model {
  padding-top: 14px;
  border-top: 1px solid #000;
  .tit_model {
    font-size: 22px;
    font-family: 'Kakao';
    font-weight: normal;
    line-height: 1;
    color: #282828;
  }
  img {
    display: block;
  }
}
.box_work {
  width: 17.37%;
  img {
    width: 72.56%;
    margin: 44px auto 0;
  }
}
.box_business {
  width: 36.19%;
  img {
    width: 100%;
    margin-top: 36px;
  }
}
.box_ai {
  width: 29%;
  img {
    width: 100%;
    margin-top: 40px;
  }
}
.ico_change {
  align-self: center;
  display: block;
  width: 31px;
  height: 28px;
  margin-top: 80px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31' height='28' viewBox='0 0 31 28' fill='none'%3E%3Cpath d='M30.6103 5.18005L22.8619 0.706522V9.65358L30.6103 5.18005ZM0.649902 5.95489H23.6368V4.40522H0.649902V5.95489Z' fill='%23353535'/%3E%3Cpath d='M0.649902 23.2812L8.39829 27.7547V18.8077L0.649902 23.2812ZM7.62345 24.056H30.6103V22.5064H7.62345V24.056Z' fill='%23353535'/%3E%3C/svg%3E")
    no-repeat;
  background-size: cover;
}
.wrap_tech {
  display: flex;
  flex-wrap: wrap;
  gap: 27px;
}
.box_tech {
  flex: 1;
  padding: 33px 20px 40px 34px;
  border-top: 1px solid #000;
  .tit_tech {
    font-size: 24px;
    font-family: 'Kakao';
    font-weight: 700;
    line-height: 1.17;
    color: #000;
  }
  .list_tech {
    display: flex;
    column-gap: 12px;
    flex-wrap: wrap;
    margin-top: 28px;
    li {
      font-size: 16px;
      line-height: 2.23;
      color: #25282f;
    }
  }
}
.list_techImgs {
  display: flex;
  column-gap: 80px;
  align-items: center;
  justify-content: center;
  margin: 17px auto 0;
  .img_kakao {
    width: 256px;
    height: auto;
  }
  .img_aws {
    width: 70px;
    height: auto;
  }
  .img_google {
    width: 195px;
    height: auto;
  }
  .img_microsoft {
    width: 123px;
    height: auto;
  }
  .img_premise {
    width: 142px;
    height: auto;
  }
}
.wrap_list {
  margin-top: 60px;
  :deep() {
    .list_cate {
      max-width: unset;
    }
    .tit_card {
      height: unset;
    }
    .area_tags {
      color: #1a1a1a;
    }
  }
}
@media screen and (max-width: 1919px) {
  .area_business {
    margin: 60px auto 0;
  }
  .tit_business {
    font-size: 20px;
  }
  .wrap_model {
    margin-bottom: 44px;
  }
  .box_model {
    padding-top: 13px;
    .tit_model {
      font-size: 20px;
    }
  }
  .box_work img {
    margin-top: 40px;
  }
  .box_business img {
    margin-top: 33px;
  }
  .box_ai img {
    margin-top: 36px;
  }
  .ico_change {
    width: 29px;
    height: 26px;
    margin-top: 70px;
  }
  .wrap_tech {
    gap: 25px;
  }
  .box_tech {
    padding: 30px 18px 34px 32px;
    .tit_tech {
      font-size: 22px;
    }
    .list_tech {
      margin-top: 24px;
      li {
        font-size: 15px;
      }
    }
  }
  .list_techImgs {
    column-gap: 74px;
    .img_kakao {
      width: 215px;
    }
    .img_aws {
      width: 58px;
    }
    .img_google {
      width: 169px;
    }
    .img_microsoft {
      width: 102px;
    }
    .img_premise {
      width: 115px;
    }
  }
}
@media screen and (max-width: 1439px) {
  .cont_main {
    padding: 120px 80px;
  }
  .tit_business {
    padding: 7px;
    font-size: 15px;
  }
  .wrap_model {
    margin-bottom: 32px;
  }
  .box_model {
    padding-top: 10px;
    .tit_model {
      font-size: 15px;
    }
  }
  .box_work img {
    margin-top: 25px;
  }
  .box_business img {
    margin-top: 20px;
  }
  .box_ai img {
    margin-top: 22px;
  }
  .ico_change {
    width: 21px;
    height: 19px;
    margin-top: 52px;
  }
  .wrap_tech {
    gap: 18px;
  }
  .box_tech {
    padding: 23px 13px 27px 23px;
    .tit_tech {
      font-size: 16px;
    }
    .list_tech {
      column-gap: 8px;
      margin-top: 16px;
      li {
        font-size: 11px;
      }
    }
  }
  .list_techImgs {
    column-gap: 54px;
    margin-top: 11px;
    .img_kakao {
      width: 189px;
    }
    .img_aws {
      width: 50px;
    }
    .img_google {
      width: 143px;
    }
    .img_microsoft {
      width: 94px;
      // height: 36px;
    }
    .img_premise {
      width: 86px;
    }
  }
}
@media screen and (max-width: 1023px) {
  .cont_main {
    padding: 120px 40px;
  }
  .tit_business {
    padding: 6px;
    font-size: 28px;
    line-height: 1.49;
  }
  .wrap_model {
    flex-direction: column;
    margin-bottom: 60px;
  }
  .box_model {
    padding-top: 24px;
    .tit_model {
      font-size: 28px;
    }
  }
  .box_work {
    width: 100%;
    img {
      max-width: 490px;
      width: 100%;
      margin-top: 42px;
    }
  }
  .box_business {
    width: 100%;
    img {
      max-width: 648px;
      margin: 42px auto 0;
    }
  }
  .box_ai {
    width: 100%;
    img {
      margin-top: 42px;
    }
  }
  .ico_change {
    width: 36px;
    height: 36px;
    margin: 60px 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36' fill='none'%3E%3Cpath d='M30 36.0001L35.7735 26.0001L24.2265 26.0001L30 36.0001ZM29 6.09914e-05L29 27.0001L31 27.0001L31 6.10789e-05L29 6.09914e-05Z' fill='%23353535'/%3E%3Cpath d='M6 6.10352e-05L0.226497 10.0001L11.7735 10.0001L6 6.10352e-05ZM5 9.00006L5 36.0001L7 36.0001L7 9.00006L5 9.00006Z' fill='%23353535'/%3E%3C/svg%3E");
  }
  .wrap_tech {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 40px;
  }
  .box_tech {
    width: 100%;
    padding: 33px 34px 30px;
    box-sizing: border-box;
    .tit_tech {
      font-size: 28px;
    }
    .list_tech {
      column-gap: 12px;
      margin-top: 26px;
      li {
        font-size: 18px;
      }
    }
  }
  .list_techImgs {
    flex-wrap: wrap;
    column-gap: 80px;
    margin: 60px auto 0;
    &::before {
      display: block;
      width: 100%;
      height: 34px;
      order: 2;
      content: '';
    }
    li:nth-of-type(n + 4) {
      order: 3;
    }
    .img_kakao {
      width: 229px;
    }
    .img_aws {
      width: 87px;
    }
    .img_google {
      width: 208px;
    }
    .img_microsoft {
      width: 152px;
      height: unset;
    }
    .img_premise {
      width: 149px;
    }
  }
  .wrap_list:deep() {
    .wrap_text .tit_card {
      -webkit-line-clamp: 2;
    }
  }
}
@media screen and (max-width: 811px) {
  .mbr {
    display: block;
  }
  .cont_main {
    padding: 80px 20px;
    &.cont_cate {
      padding: 60px 20px;
      .tit_main {
        text-align: center;
      }
    }
  }
  .tit_main {
    font-size: 24px;
    line-height: 1.5;
  }
  .tit_business {
    padding: 13px;
    font-size: 18px;
    line-height: 1.67;
  }
  .box_model {
    padding-top: 16px;
    .tit_model {
      font-size: 18px;
    }
  }
  .box_work img,
  .box_business img,
  .box_ai img {
    margin-top: 35px;
  }
  .ico_change {
    width: 24px;
    height: 24px;
    margin: 40px 0;
  }
  .wrap_tech {
    gap: 36px;
  }
  .box_tech {
    padding: 11px 0 0;
    .tit_tech {
      font-size: 16px;
      line-height: 1.38;
    }
    .list_tech {
      column-gap: 8px;
      margin-top: 14px;
      li {
        font-size: 10px;
      }
    }
  }
  .list_techImgs {
    column-gap: 40px;
    margin-top: 56px;
    &::after {
      display: block;
      width: 100%;
      height: 34px;
      content: '';
      order: 4;
    }
    li:nth-of-type(3) {
      order: 3;
    }
    li:nth-of-type(n + 4) {
      order: 5;
    }
    .img_kakao {
      width: 172px;
    }
    .img_aws {
      width: 47px;
    }
    .img_google {
      width: 166px;
    }
    .img_microsoft {
      width: 116px;
    }
    .img_premise {
      width: 100px;
    }
  }
  .wrap_list {
    margin-top: 40px;
  }
}
</style>
